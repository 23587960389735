.heading-font {
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}
.body-font {
  font-family: 'Muli', 'Helvetica', 'Arial', 'sans-serif';
}
.alt-font {
  font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
  text-transform: uppercase;
  letter-spacing: 2px;
}
