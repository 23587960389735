$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "new-age.scss";

// endbower

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.swiper-container {
  width: 600px;
  height: 300px;
}

/* Space out content a bit */
body {
  background: rgb(212,23,23);
}


a{
  color:black;
}
a:hover{
  color:black;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing{
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;

  /* Make the masthead heading the same height as the navigation */
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 19px;
  }
}

/* Custom page footer */
.footerBar {
  padding-top: 19px;
  color: #777;
}

#wrapper
{
  overflow:hidden;
  width:100%;
}

#wrapper ul
{
  list-style:none;
  margin:0;
  padding:0;
  -webkit-transition: -webkit-transform 0.3s linear;
}
li{
  height:100%;
}


#wrapper ul li
{
  float:left;
}

.slides{

  margin-right: 13px;

}

.slidesFirst{
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  .btn {
    font-size: 21px;
    padding: 14px 24px;
  }
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
  p + h4 {
    margin-top: 28px;
  }
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing{
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }




}
