//// Variables
@font-face {
  font-family: GothamCond-XBlack;
  src: url(https://s3-eu-west-1.amazonaws.com/movefast-quickhost/tatort-branchio/GothamCond-XBlack.otf);

}
@font-face{
  font-family: GothamNarrow-Light;
  src: url(https://s3-eu-west-1.amazonaws.com/movefast-quickhost/tatort-branchio/GothamNarrow-Light.otf);
}

