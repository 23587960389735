@import "variables.scss";
@import "mixins.scss";

.footer{
  width:100%;
}

html {
  height: 100%;
  width: 100%;
}


.leftArrowStyle{
 position: absolute;
  top:40%;
  left:5%;
  font-size: 30px;
}

.rightArrowStyle{
  position: absolute;
  top:40%;
  right:5%;
  font-size: 30px;
}

.invertcolors{
  color:white;
}


body {
  width: 100%;
  height:100%;
}
a {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: black;
  font-family:GothamNarrow-Light;
  font-size: 16px;
}
hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-color: rgba(34, 34, 34, 0.1);
}
hr.light {
  border-color: white;
}
.footertxt{
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: GothamNarrow-Light;
}

h1 {
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}
h2 {
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}
h3 {
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}
h4 {
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}
h5 {
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}
h6 {
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
}


#tatortLogo{
  width:50%;
  margin:0 auto;
  margin-bottom: 5%;
}

.appstore {
  margin:0 auto;
  height:50px;
}


.navbar-default {
  background-color: rgb(212,23,23);
  border-color: rgba(34, 34, 34, 0.05);
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  font-weight: 200;
  letter-spacing: 1px;
  .navbar-header {
    .navbar-brand {
      font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
      font-weight: 200;
      letter-spacing: 1px;
      color: white;
      font-family: GothamCond-XBlack;

    }

  }
  .nav {
    >li {
      >a {
        font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 11px;
        color: #222222;

      }
    }
    >li.active {
      >a {
        color: #fdcc52 !important;
        background-color: transparent;

      }
    }
  }
}
.col-sm-7{
  padding:0;
}



.headerappstore{
  text-align: right;
  position:absolute;
  right:0;
  height:100%;
  padding-top:7px;
  padding-bottom:15px;
  padding-right:15px;

}

.mobileShow { display: none;}

@media(min-width : 320px)
and (max-width : 480px)
{ .mobileShow { display: inline;}}

@media (max-width : 700px) and (max-height : 400px) {
  .mobileShow { display: inline;}
}


header {
  margin: 0 auto;
  position: relative;
  width: 100%;
  overflow-y: hidden;
  background: rgb(212,23,23);
  background-repeat: no-repeat;
  color: white;
  .header-content {
    position: relative;
    .header-content-inner {
      text-align: center;
      background: white;
      position: relative;
      width:100%;
      margin: 0 auto;
      padding: 5%;
      h1 {
        font-family: GothamCond-XBlack;
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 25px;
        color:black;
      }
      a{
        text-decoration: none;
      }
      #contact-email{
        text-decoration: underline;
        float: right;
      }
      .list-badges {
        margin-bottom: 25px;
        img {
          height: 50px;
          margin-bottom: 25px;
        }
      }
    }
  }
  .device-container {
    //  width:70%;
    //margin: 0 auto;
    //margin-top: 20%;
    //margin-bottom: -38%;
    .screen {
      img {
      }
    }
  }
}
section {
  padding: 20px 0;
  h2 {
    font-size: 50px;
  }
}







footer {
  background-color: #222222;
  padding: 10px 0;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  p {
    font-size: 12px;
    margin: 0;
  }
  ul {
    margin-bottom: 0;
    li {
      a {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);

      }

    }
  }
}
.bg-primary {
  background: rgb(212,23,23);

}
.text-primary {
  color: #fdcc52;
}
.no-gutter {
  >[class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}
.btn-outline {
  color: white;
  border-color: white;

}
.btn-outline.active {
  color: white;
  background-color: #fdcc52;
  border-color: #fdcc52;
}
.btn {
  font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 300px;
}
.btn-xl {
  padding: 15px 45px;
  font-size: 11px;
}

img {
  &::selection {
    color: white;
    background: transparent;
  }
  &::-moz-selection {
    color: white;
    background: transparent;
  }
}
@media (max-width: 768px) {
  .device-container{
    width:80%;
    margin: 0 auto;
    margin-top: 10px;

  }

  .phoneDiv{
    margin-bottom: -28%;
  }


}
@media (min-width: 768px) {

  .device{
  }

  .footertxt{
    font-size: 12px;

  }
  .navbar-default {
    background-color: rgb(212,23,23);;
    border-color: white;
    .navbar-header {
      .navbar-brand {
        font-family: GothamCond-XBlack;
        color: white;

      }
    }
    .nav {
      >li {
        >a {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }

  .phoneDiv{
    position: relative;
    height:100%;
  }

  .device-container{
    position: absolute;
    left: 0;
    right: 30px;
    bottom: calc((100% - 554px)/2);
  }


  header {

    .header-content {
      text-align: left;
      margin:0 auto;
      height: 100%;

      .header-content-inner {
        text-align: center;
        max-width: none;
        position: absolute;
        bottom: calc(     ((100% - 554px)/2)  + 1.5px   );
        margin-top: 19%;
        h1 {
          font-size: 35px;
        }
      }
    }
    .device-container {

    }
  }


}
@media (min-width: 992px) {

  .device{
  }

  .footertxt{
    font-size: 15px;

  }
  header {
    .header-content {
      .header-content-inner {
        text-align: center;
        h1 {
          font-size: 35px;

        }
      }
    }
  }

}
@media(min-height: 792px) {
  .device{
    //margin-top: 30%;
  }
  .device-container {

    //width: 110%;
  }


}

@media(min-height: 980px){
  .device{
    //margin-top: 40%;

  }
  .device-container {

    //width: 120%;
  }

}
